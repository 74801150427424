.datos-alquiler {

    page[size="LEGAL"] {
        background: white;
        width: 216mm;
        height: 316mm;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
        box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    }
    
    @media print {
        body, page[size="A4"] {
            margin: 0;
            box-shadow: 0;
        }
    }
    
    #content {
        padding: 2em 3em 0em 3em;
    }
    
    h1, h2 {
        color: rgb(35, 31, 32);
        text-align: center;
        -webkit-margin-before: 0em;
        -webkit-margin-after: 0em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
    }
    
    h2 {
        color: rgb(65, 61, 62);
        font-weight: normal;
        letter-spacing: 2px;
    }
    
    h3 {
        font-weight: normal;
        text-decoration: underline;
        font-size: 1em;
        -webkit-margin-after: 0.3em;
        -webkit-margin-end: 0px;
    }
    
    table {
        border-collapse: collapse;
        width: 100%;
    }
    
    table.fields {
        width: 100%;
    }
    
    .right-border {
        border-left: 1px solid;
    }
    
    .top-border {
        border-top: 1px solid;
    }
    
    td {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    ul.leaders {
        max-width: 40em;
        padding: 0;
        overflow-x: hidden;
        list-style: none
    }
    
    ul.leaders li:before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: "________________________________________"
        "________________________________________"
        "________________________________________"
        "________________________________________"
    }
    
    ul.leaders span:first-child {
        padding-right: 0.33em;
        background: white
    }
    
    ul.leaders span+span {
        float: right;
        background: white;
        padding: 0px 4px;
        color: black;
        font-weight: bold;
    }
    
    .products_list li span {
        color: black;
        font-weight: bold;
    }
    
    .min-size {
        min-height: 1em;
    }
    
    #prod_obs {
        min-height: 3em;
        width: 100%;
    }
    
    hr {
        border: none;
        border-top: 2px dashed;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .logo {
        width: 80px;
        margin: auto;
    }
    
    .logo img {
        width: 80px;
    }
    
    .sizer {
        width: 298px;
    }
    
    li p {
        -webkit-margin-before: 0em;
        -webkit-margin-after: 0em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
    }
    td p {
        -webkit-margin-before: 0em;
        -webkit-margin-after: 0em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
    }
    
    ol {
        -webkit-margin-before: 0.2em;
    }
    
    .first-paragraph {
        margin-top: 0.2em;
    }
    
    .text-center {
        text-align:center;
    }
}