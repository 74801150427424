$screen-lg-min: 992px;
$screen-md-min: 768px;
$screen-sm-min: 400px;
$screen-xs-min: 320px;

@mixin xs {
    @media (min-width: #{$screen-xs-min}) {
        @content;
    }
 }

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }