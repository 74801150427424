@import '../../app.scss';

@font-face {
    font-family: 'NexaLight';
    src: url(../../public/fonts/NexaLight.ttf) format('truetype');
}

.logo {
    font-family: 'NexaLight', sans-serif;
    font-size: 30px;
    color: #00008b;
    font-weight: bold;
    letter-spacing: 4px;

    @include xs {
        letter-spacing: 3px;
        text-align: center;
    }

    @include sm {
        letter-spacing: 3px;
    }

    @include lg {
        letter-spacing: 4px;
        text-align: inherit;
    }
}