@import '../../public/style.scss';
@import '../../app.scss';

.rent-form {
    @include xs {
        margin-top: 6rem;
    }

    @include sm {
        margin-top: 12rem;
    }
    font-family: $josefin;
    font-size: 1.3rem;
}