@import '../../public/style.scss';
@import '../../app.scss';

.wizard-container {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    position: relative;
    align-items: center;
}

.console {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    bottom: 0;
    justify-content: space-around;
    position: absolute;

    @include xs {
        position: relative;
    }

    @include sm {
        position: fixed;
    }

    @include md {
        position: fixed;
    }
}

.wizard-message {
    color: crimson;
}

.wizard-card {
    h2 {
        // color: $color-strong;
        letter-spacing: 2px;
    }

    @include xs {
        text-align: center;

        h2 {
            margin-bottom: 1rem;
        }
    }

    @include md {
        width: 80%;

        h2 {
            margin-bottom: 3rem;
        }
    }

    h3 {
        color: rgb(85, 85, 85);
        text-decoration: underline;
    }

    ul {
        padding: 0;
        font-family: $josefin;
        font-size: 1.2rem;

        li {
            margin-bottom: 1rem;
            list-style: none;
        }
    }

    hr {
        width: 50%;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
}